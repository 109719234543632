import React, { useEffect, useState } from 'react';
import './topbar.css';
import { NavLink, useLocation, useNavigate} from 'react-router-dom';
import {  Backdrop, Button, Grid, Menu , MenuItem, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Person , Key, Logout} from '@mui/icons-material';
import APIFunctions from '../../ApiFunction';
import { BASE_URL } from '../../Api';
import { setCount } from '../../slice/count';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../slice/userInfo';

export default function Topbar() {
    const user = useSelector(state => state.userinfo.user);
	const navigate = useNavigate();
    const disptch = useDispatch()
   
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutClick = async (e) => {
        try{
            let result = await APIFunctions.logout();
            if(result.data.code===200){       
                localStorage.clear('admintoken');
                sessionStorage.clear('admintoken');
                toast.success('Logout Successful !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate('/login')
            }
        }catch(err){
            console.error(err.message)
        }     
    }
    
    useEffect(()=>{
        disptch(setCount(JSON.parse(localStorage.getItem('count'))))
        disptch(setUser(JSON.parse(localStorage.getItem('user'))))
    },[])
    return (
        <div className='topbarWrapper'>
            <div className='logoContainer'>
                <span className='spanName'>CARFO</span>
            </div>
            <div className='topRight'>
               
                <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{shadow: 'none'}}
                >
                    {user && user.profilePic ?
                        (<img src={`${BASE_URL}uploads/images/profiles/${user?.profilePic?? ""}`}  alt='topAvatar' className='topAvatar' />)
                        :
                        (<img src='/images/blank_pic.png'  alt='topAvatar' className='topAvatar' />)
                    }
                </Button>
                <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <NavLink to='/admin/profile' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Person className='menuIcons'/>Profile</MenuItem>
                        </div>
                    </NavLink>
                    <NavLink to='/admin/change-password' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Key className='menuIcons' />Change Password</MenuItem>
                        </div>
                        </NavLink>
                    <MenuItem onClick={logoutClick}><Logout className='menuIcons'/>Logout</MenuItem>
                    <ToastContainer/>
                </Menu>
            </div>
        </div>
    )

}
