import React, { useState } from "react";
import "./changePass.css";
import axios from "axios";
import "./profile.css";
import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../components/loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material/";
import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import APIFunctions from "../../ApiFunction";
import { DetailLabel } from "../../components/Styles";

const BackPaper = styled(Paper)({
  padding: "20px",
  height: "540px",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});
export default function ChangePass() {
  const [inputPass1, setInputPass1] = useState("password");
  const [inputPass2, setInputPass2] = useState("password");
  const navigate = useNavigate();

  const [one, setOne] = useState({
    oldPassword: "",
    newPassword: "",
    retypePassword: "",
  });

  const inputEvent = (e) => {
    const { id, value } = e.target;
    setOne((prevalue) => {
      return {
        ...prevalue,
        [id]: value,
      };
    });
  };

  const submitVal = async (event) => {
    event.preventDefault();
    if (one.newPassword !== one.retypePassword) {
      toast.error("New password not matched!");
    } else {
      const result = await APIFunctions.changePass(one);
      if (result.data.code === 200) {
        toast.success("Password changed successfully!");
        setOne({ oldPassword: "", newPassword: "", retypePassword: "" });
      } else if (result.data.code === 203) {
        toast.warning("Old password does not match.");
      } else if (result.data.code === 401) {
        localStorage.clear("admintoken");
        sessionStorage.clear("admintoken");
        navigate("/login");
        toast.info("Session expired");
      }
    }
  };

  return (
    <>
      <Toptag />
      <ToastContainer />
      <BackPaper>
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <DetailLabel>Old Password</DetailLabel>
          </Grid>
          <Grid item className="w-100">
            <input
              type="password"
              name="oldPassword"
              value={one.oldPassword}
              id="oldPassword"
              className="searchBarInput"
              onChange={inputEvent}
            />
          </Grid>

          <Grid item>
            <DetailLabel>New Password</DetailLabel>
          </Grid>
          <Grid item className="w-100">
            <input
              type="password"
              name="newPassword"
              value={one.newPassword}
              id="newPassword"
              className="searchBarInput"
              onChange={inputEvent}
            />
          </Grid>
          <Grid item>
            <DetailLabel>Confirm Password</DetailLabel>
          </Grid>
          <Grid item className="w-100">
            <input
              type="password"
              name="retypePassword"
              value={one.retypePassword}
              id="retypePassword"
              className="searchBarInput"
              onChange={inputEvent}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ marginTop: "50px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {one.oldPassword === "" || one.newPassword === "" || one.retypePassword === "" ? (
              <button className="newBtn" disabled> Update Password </button>
            ) : (  
            <Tooltip title="Click to Update Password">
                <button  className="newBtn" onClick={submitVal} >
                  Update Password
                </button>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </BackPaper>
    </>
  );
}
