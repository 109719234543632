const BASE_URL = process.env.REACT_APP_BASE_URL

const API = {
    LOGIN: BASE_URL + 'api/v1/admin/admin_login',
    LOGOUT: BASE_URL + 'api/v1/admin/admin_logout',
    FORGOT_PASSWORD: BASE_URL + 'api/v1/admin/forgot_password',
    DETAILS: BASE_URL + 'api/v1/admin/admin_details',
    EDIT_ADMIN: BASE_URL + 'api/v1/admin/edit_admin_detail',
    CHANGE_PASS: BASE_URL + 'api/v1/admin/change_password',
    //user section
    CREATE_USER: BASE_URL + 'api/v1/admin/create_user',
    GET_ALL_USERS: BASE_URL + 'api/v1/admin/get_all_users',
    GET_ALL_CREATED_ADMIN : BASE_URL + 'api/v1/admin/created_by_admin_users',
    GET_ALL_SUB_ADMIN: BASE_URL + 'api/v1/admin/get_all_sub_admins',
    GET_ALL_PREMIUM_USERS: BASE_URL + 'api/v1/admin/get_all_premium_users',
    BLOCK_USER: BASE_URL + 'api/v1/admin/block_User/',
    REMOVE_USER: BASE_URL + 'api/v1/admin/remove_user/',
    EDIT_USER: BASE_URL + 'api/v1/admin/edit_detail/',
    COUNT: BASE_URL + 'api/v1/admin/user_count',
    GET_REMOVE_USERS: BASE_URL + 'api/v1/admin/get_removed_users',
    ANALYTICS: BASE_URL + 'api/v1/admin/users_analytics',
    GET_USER_DETAIL: BASE_URL + 'api/v1/admin/user_detail/',
    ADD_TO_WEBSITE: BASE_URL + 'api/v1/admin/add_to_website/',
    //assistant section
    CREATE_ASSISTANT: BASE_URL + 'api/v1/admin/create_assitant',
    GET_ALL_ASSISTANT: BASE_URL + 'api/v1/admin/get_all_assistant',
    GET_ASSISTANT: BASE_URL + 'api/v1/admin/get_assistant/',
    EDIT_ASSISTANT: BASE_URL + 'api/v1/admin/edit_assistant/',
    //topic section
    CREATE_TOPIC: BASE_URL + 'api/v1/admin/create_topic',
    ALL_TOPIC: BASE_URL + 'api/v1/admin/get_all_topic',
    HIDE_TOPIC: BASE_URL + 'api/v1/admin/hide_topic/',
    DELETE_TOPIC: BASE_URL + 'api/v1/admin/delete_topic/',
    //faq section
    CREATE_FAQ: BASE_URL + 'api/v1/admin/create_faq',
    ALL_FAQ: BASE_URL + 'api/v1/admin/all_faq',
    FAQ: BASE_URL + 'api/v1/admin/faq/',
    EDIT_FAQ: BASE_URL + 'api/v1/admin/edit_faq/',
    DELETE_FAQ: BASE_URL + 'api/v1/admin/delete_faq/',
    //article section
    CREATE_ARTICLE: BASE_URL + 'api/v1/admin/create_article',
    ALL_ARTICLE: BASE_URL + 'api/v1/admin/get_all_article',
    ARTICLE: BASE_URL + 'api/v1/admin/get_article_detail/',
    TOGGLE_PUBLISH_ARTICLE: BASE_URL + 'api/v1/admin/toggle_hide_article/',
    EDIT_ARTICLE: BASE_URL + 'api/v1/admin/edit_article_detail/',
    DELETE_ARTICLE: BASE_URL + 'api/v1/admin/delete_article/',
    //contact-section
    ALL_CONTACT: BASE_URL + 'api/v1/admin/all_contacts_us',
    CONTACT: BASE_URL + 'api/v1/admin/contacts_us/',
    //user post section
    GET_ALL_POST: BASE_URL + 'api/v1/admin/get_all_post',
    EDIT_POST: BASE_URL + 'api/v1/admin/edit_post/',
    GET_POST_DETAIL: BASE_URL + 'api/v1/admin/get_post_detail/',
    DELETE_POST: BASE_URL + 'api/v1/admin/delete_post/',
    //report post section
    All_REPORTED_POST: BASE_URL + 'api/v1/admin/get_all_reported_post',
    REPORTED_POST_DETAIL: BASE_URL + 'api/v1/admin/get_report_post_detail/',
    DELETE_REPORTED_POST: BASE_URL + 'api/v1/admin/delete_report_post/',
    GET_ALL_REPORTED_PROFILES : BASE_URL + 'api/v1/admin/get_all_reported_profiles',

    //report comment section
    All_REPORTED_COMMENT: BASE_URL + 'api/v1/admin/get_all_reported_comment',
    DELETE_REPORTED_COMMENT: BASE_URL + 'api/v1/admin/delete_reported_comment/',
    //model section
    CREATE_CAR_MODEL: BASE_URL + 'api/v1/admin/create_model',
    ALL_CAR_MODEL: BASE_URL + 'api/v1/admin/get_all_model',
    DELETE_CAR_MODEL: BASE_URL + 'api/v1/admin/delete_model/',
    //content 
    GET_PRIVACY: BASE_URL + 'api/v1/admin/get_privacy',
    EDIT_PRIVACY: BASE_URL + 'api/v1/admin/edit_privacy/',
    GET_TERMS: BASE_URL + 'api/v1/admin/get_terms',
    EDIT_TERMS: BASE_URL + 'api/v1/admin/edit_terms/',
    //get purchase
    GET_PURCHASE: BASE_URL + 'api/v1/admin/all_purchase',
    GET_MEMBERSHIP_USER: BASE_URL + 'api/v1/admin/all_membership',
    GET_ALL_PRICING: BASE_URL + 'api/v1/admin/get_pricing',
    EDIT_PRICING: BASE_URL + 'api/v1/admin/edit_pricing',


    //group section
    GET_ALL_GROUPS: BASE_URL + 'api/v1/admin/get_all_groups',
    GET_GROUP_DETAILS: BASE_URL + 'api/v1/admin/get_group_detail',
    DELETE_GROUP: BASE_URL + 'api/v1/admin/delete_group',
    DELETE_GROUP_POST: BASE_URL + 'api/v1/admin/delete_group_post',

    //business section
    GET_ALL_DIRECTORIES: BASE_URL + 'api/v1/admin/get_all_directories',
    VIEW_DETAILS: BASE_URL + 'api/v1/admin/view_directory_details',
    GET_ALL_BUSINESS_DRAFT: BASE_URL + 'api/v1/admin/get_all_saved_draft',
    ARCHIVED_BUSINESS: BASE_URL + 'api/v1/admin/archived_directories',
    DELETE_DRAFT_BUSINESS: BASE_URL + 'api/v1/admin/delete_draft_profile',
    GET_ALL_ARCHVIED_BUSINESS: BASE_URL + 'api/v1/admin/get_all_archived_business'
}

export default API
export { BASE_URL }
