import { Archive, Block, Cancel, Delete, DeleteOutline, DoneAll, FreeBreakfastSharp, MarkEmailRead, Markunread, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'


const Directories = () => {
  const businessCount = useSelector(state => state.count.counts.businessCount);
  const [isLoading, setIsLoading] = useState(true)
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [archived, setArchOpen] = useState(false);
  const [id, setId] = useState()









  const handleArchivedOpen = (id) => {
    setId(id)
    setArchOpen(true);
  };

  const handleClose = () => {
    setArchOpen(false);
  };


  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }








  const getAll = async () => {
    try {
      const result = await APIFunction.getAllDirectories(key, row, skip);
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate('/login')
          toast.info("Session expired")
        }
        toast.error(result.data.message)
      } else {
        setAll(result.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error.message)
    }

  }


  const archivedBusinessProfile = async (id) => {
    try {
      let obj = {
        id: id
      }
      const result = await APIFunction.archivedBusiness(obj);
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate('/login')
          toast.info("Session expired")
        }
      } else {
        toast.success("Business profile has been archived successfully!")
        setArchOpen(false);
        getAll();
      }

    } catch (error) {
      console.log(error.messagef)
    }
  }





  const handleChange = (e) => {
    if (e.target.value === businessCount) {
      setSkip(0)
      setRow(businessCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }

  useEffect(() => {
    getAll()
  }, [key, row, skip])
  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Business Profiles: {businessCount}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value={toString(businessCount)}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Business porfile not registered with us!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell >Business Name</TableCell>
                        <TableCell >Business Email</TableCell>
                        <TableCell>Created On</TableCell>
                        <TableCell >Plan Expired On</TableCell>
                        <TableCell>Verfication</TableCell>
                        <TableCell >Status</TableCell>
                        {/* <TableCell >User Deletion</TableCell> */}
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {

                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{element.business_name && element.business_name.slice(0,20)}...</TableCell>
                            <TableCell>{element.business_email}</TableCell>
                            <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell>{moment(element.endDate).format('LL')}</TableCell>
                            <TableCell>
                              {element.isVerified ? (
                                <Chip label="Verified" className="statusChipActive" />)
                                : (
                                  <Chip label="Pending" className="statusChipPending" />)
                              }</TableCell>
                            <TableCell>
                              <div className="viewText">
                                {element.isExpired ?
                                  'Expired'
                                  : 'Premium'}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to={'/admin/business-directories/' + element._id} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title="Archived Profile From Website" >
                                    <Archive className="userListDelete" onClick={() => { handleArchivedOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }


              {/* dilogue for delete user */}
              <Dialog open={archived} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to archived this business from website?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose}>No</Button>
                  <Button onClick={() => { archivedBusinessProfile(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>

            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(businessCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}

    </>
  )
}

export default Directories









