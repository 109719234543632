import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";



const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Privacy = () => {
    const [state, setState] = useState('');
    const [id,setId] = useState('')
    const navigate = useNavigate();

    const handleChange = data => {
        setState(data)
    };
    const updateContent = async () => {
        let data = {
            body:state
        }
        try {
            let result = await APIFunctions.editPrivacy(id,data)
            if (result.data.code === 200) {
                toast.success("Successfully updated!")
            } else if (result.data.code === 401) {
                localStorage.clear('admintoken');
                sessionStorage.clear('admintoken');
                navigate("/login")
                toast.info("Session expired")
            }
        } catch (err) {
            console.error(err.message)
        }
    }
    const getAll = async () => {
        try {
            let result = await APIFunctions.getPrivacy()
            if (result.data.code === 200) {
                setState(result.data.data[0].body)
                setId(result.data.data[0]._id)
            } else if (result.data.code === 401) {
                localStorage.clear('admintoken');
                sessionStorage.clear('admintoken');
                navigate("/login")
                toast.info("Session expired")
            }
        } catch (err) {
            console.error(err.message)
        }
    }
    useEffect(() => {
        getAll()
    }, [])


    return (
        <div className='container-fluid'>
            <Toptag />
            <ToastContainer />
            <BackPaper>
                <EditorToolbar />
                <ReactQuill
                    style={{ height: '470px', overflow: "auto" }}
                    theme="snow"
                    id='quillState'
                    value={state}
                    onChange={handleChange}
                    placeholder={"Write something..."}
                    modules={modules}
                    formats={formats}
                />
                <Grid container style={{ marginTop: "10px" }} display="flex" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <button className="newBtn" onClick={updateContent}>SUBMIT</button>
                    </Grid>
                </Grid>
            </BackPaper>
        </div>
    )
}

export default Privacy