import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "../user/userDetail.css"
import APIFunctions from '../../ApiFunction'


const BackPaper =  styled(Paper)({
  height:"560px",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const FaqDetails = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [det,setDet] = useState([]);
  const [dis,setDis] = useState(true);
  const [obj,setObj] = useState({title:'',message:''})
  
  
  const addata = (e,i) => {
    // let temp = [...det]
    // temp[i][e.target.name] = e.target.value
    // setDet(temp)
    setObj({
        ...obj,
        [e.target.name] : e.target.value,
     })  
  }
 
  const getAll = async ()=>{
    let id = location.state.id
    try{
      let result = await APIFunctions.faq(id)
      if(result.data.code===200){
        setIsLoading(false)
        setObj(result.data.data)
      }else if(result.data.code===401){
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate("/login")
        toast.info("Session expired")
      }
    }catch(err){
      console.error(err.message)
    }
    } 
    const updateFaq = async ()=>{
      let id = location.state.id
      try{
        let result = await APIFunctions.editFaq(id,obj)
        if(result.data.code===200){
          setDis(true)
          getAll();
          toast.success("Updated successfully")
        }else if(result.data.code===401){
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate("/login")
          toast.info("Session expired")
        }
      }catch(err){
        console.error(err.message)
      }
      } 
    useEffect(()=>{
      setIsLoading(true)
      getAll()
    },[])
  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
    <ToastContainer />
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>FAQ</Grid>
      {dis?<Grid item><button className='newBtn'  onClick={()=>{setDis(false)}}>Edit</button></Grid>:null}  
    </Grid>
    <hr></hr>
      <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item>Question</Grid>
      <Grid item style={{ width: "100%" }}>
      <textarea type="text" style={{height:"120px"}} disabled={dis?true:false} value={obj.title}  onChange={addata} name="title" id="title" className="detailBarInput" />
      </Grid>
      <Grid item>Answer</Grid>
      <Grid item style={{ width: "100%" }}>
        <textarea type="text" style={{height:"180px"}} disabled={dis?true:false}    value={obj.message}  onChange={addata} name="message" id="message" className="detailBarInput" />
      </Grid>
      {!dis?<Grid item><button className='newBtn' onClick={updateFaq}>Update</button></Grid>:null}  
      </Grid>
    </BackPaper>
    </>
    }
   
    </>
  )
}

export default FaqDetails

