import "./login.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader/Loader";
import APIFunction from "../../ApiFunction";
import { setCount } from "../../slice/count";
import { useDispatch } from "react-redux";
import { setUser } from "../../slice/userInfo";

export default function Login() {
  const navigate = useNavigate();
  const [style, setStyle] = useState("container1 left-panel-active");
  const [loader, setLoader] = useState(false);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState(false);
  const disptch = useDispatch();

  const changeStyle1 = () => {
    setStyle("container1 right-panel-active");
  };
  const changeStyle2 = () => {
    setStyle("container1 left-panel-active");
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    setObj((prevalue) => {
      return {
        ...prevalue,
        [id]: value,
      };
    });
  };


  const getCounts = async () => {
    try {
      let result = await APIFunction.countUser();
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear("admintoken");
          sessionStorage.clear("admintoken");
          navigate("/login");
          toast.info("Session expired");
        }
        toast.error(result.data.message);
      } else {
        disptch(setCount(result.data.result));
        localStorage.setItem("count", JSON.stringify(result.data.result));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      setLoader(true);
      const result = await APIFunction.login(obj);
      if (!result.data.status) {
        if (result.data.code === 201) {
          toast.error(result.data.message);
          setLoader(false);
        }
      } else {
        disptch(setUser(result.data.user));
        localStorage.setItem("admintoken", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.user));
        getCounts();
        toast.success("Login successfully");
        await delay(1000);
        setLoader(false);
        navigate("/admin/home");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const submitFormForgotPass = async (event) => {
    event.preventDefault();
    try {
      const result = await APIFunction.forgotPassword(obj);
      if (result.data.code === 200) {
        setMessage(true);
      } else if (result.data.code === 201) {
        toast.info("Account does not exist!");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="fill_center">
        <div className={style} id="container">
          <div className="form-container sign-up-container">
            <form onSubmit={submitFormForgotPass}>
              {message === true ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="message">Email Verified Successfully!</div>
                    <div className="paraMessage">
                      Please check your email we send you one time password!
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src="/images/top-logo.png"
                    alt=""
                    className="loginLogo"
                  />
                  <h1 className="heading2">Recover Account</h1>
                  <input
                    type="email"
                    id="email"
                    onKeyDown={handleKeyDown}
                    onChange={inputEvent}
                    required={true}
                    placeholder="Email"
                  />
                  <button className="newBtn" style={{ marginTop: "17px" }}>
                    Submit
                  </button>
                </>
              )}
            </form>
          </div>

          <div className="form-container sign-in-container">
            <form onSubmit={submitForm}>
              <img src="/images/top-logo.png" alt="" className="loginLogo" />
              <h1 className="heading1">SIGN IN</h1>
              <input
                type="email"
                id="email"
                onKeyDown={handleKeyDown}
                onChange={inputEvent}
                required={true}
                placeholder="Email"
              />
              <input
                type="password"
                id="password"
                onKeyDown={handleKeyDown}
                onChange={inputEvent}
                required={true}
                placeholder="Password"
              />
              <button className="newBtn" style={{ marginTop: "17px" }}>
                {loader?'Sign In...':'Sign In'}
              </button>
            </form>
          </div>

          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Hello!</h1>
                <p>Please log in</p>
                <button className="ghost" id="signIn" onClick={changeStyle2}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Welcome Back!</h1>
                <p>Click below to recover your password</p>
                <button className="ghost" id="signUp" onClick={changeStyle1}>
                  Forgot Password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
