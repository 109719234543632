import { Add, Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'

const Remove = () => {
  const [all, setAll] = useState([]);
  const [user, setUser] = useState()
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()






  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const searchHandle = (event) => {
    setKey(event.target.value)
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));







  const getAll = async () => {
    if (key) {
      setIsLoading(false)
    }
    const result = await APIFunction.getRemoveUser(key, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.data)
      await delay(1000);
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    if (key === "" && row === "" && skip === "") {
      setIsLoading(true)
    }
    getAll()
  }, [key, row, skip])

  const removeUser = async (id) => {
    const result = await APIFunction.removeUser(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      toast.success("Successfully updated!")
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }



  const BlockUser = async (id) => {
    const result = await APIFunction.blockUser(id);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }


  const handleChange = (e) => {
    if (e.target.value === user) {
      setSkip(0)
      setRow(user)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }
  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
           {all.length === 0?null: <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>}
            <BackPaper>
              {all.length === 0  ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Empty Removed Users!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell>Points</TableCell>
                        <TableCell>Sign Up</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.profilePic !== '' ? (
                                <img src={`${BASE_URL}uploads/images/profiles/${element.profilePic}`} style={{ width: "30px", height: "30px", borderRadius: "50px" }} alt="" />
                              ) : (
                                <img src="/images/blank_pic.png" style={{ width: "30px", height: "30px", borderRadius: "50px" }} alt="" />

                              )}
                            </TableCell>
                            <TableCell>{element.name}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>{element.points}</TableCell>
                            <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell>
                              {element.isActive === true ? (
                                <Chip label="Active" className="statusChipActive" />)
                                : (
                                  <Chip label="Blocked" className="statusChipBlock" />)
                              }</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="Add User To Website" >
                                    <Add className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status === false ? ("Are you sure want to unblock this user?") : ("Are you sure want to block this user?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleBlock}>Cancel</Button>
                  <Button onClick={() => { BlockUser(id) }} >{status === false ? 'unBlock' : 'Block'}</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to add this user to website?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={() => { removeUser(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>


            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(user / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}

    </>
  )
}

export default Remove









