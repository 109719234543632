import { createSlice } from '@reduxjs/toolkit';



export const countSlice = createSlice({
  name: 'count',

  initialState: {
    counts: {
      allUsersCount: 0,
      contactUsCount: 0,
      membershipCount: 0,
      purchaseCount: 0,
      removedUsersCount: 0,
      reportCommentCount: 0,
      businessCount: 0,
      reportPostCount: 0,
    }

  },

  reducers: {
    setCount: (state, action) => {
      state.counts = action.payload;
    },
  },
})



export const { setCount } = countSlice.actions

export default countSlice.reducer