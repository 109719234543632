import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Toptag from '../../components/topTag/Toptag'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';
import APIFunctions from '../../ApiFunction';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BackPaper = styled(Paper)({
  height: "630px",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Analtyics = styled(Paper)({
  borderRadius: "0",
  height: "165px",
  padding: "23px",
  cursor: "pointer",
  boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
})
const Analytic = () => {
  const [ana, setAna] = useState([])
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
  };
  //for last 6 months
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const data = {
    labels,
    datasets: [
      {
        label: 'User Register Last 6 Months',
        data: Array(labels.length).fill(0).map((value, index) => (index >= labels.length - 6 ? ana.data_Six : value)),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  //for last 30 days
  const labels2 = Array.from({ length: 30 }, (_, index) => `Day ${index + 1}`);
  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: 'User Register Last 30 Days',
        data: Array(labels2.length).fill(0).map((value, index) => (index >= labels2.length - 6 ? ana.data_Days : value)),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usersCount = useSelector(state => state.count.counts.allUsersCount);
  const isLoading = useSelector(state => state.count.isLoading);
  const error = useSelector(state => state.count.error);
  if (error === 401) {
    localStorage.clear('admintoken');
    sessionStorage.clear('admintoken');
    navigate("/login")
    toast.info("Session expired")
  }

  const getData = async () => {
    const result = await APIFunctions.analytics()
    if (result.data.code === 200) {
      setAna(result.data)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/login")
      toast.info("Session expired")
    }

  }

  let Userdata = localStorage.getItem("user");
  const present = JSON.parse(Userdata);

  return (
    <>
      {isLoading ? <Loader /> : <>
        <Toptag />
        <BackPaper>
          {present.role === 'sub-admin' ? null :
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>User Analytics</Grid>
                <Grid item>
                </Grid>
              </Grid>
              <hr></hr>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <NavLink to="/admin/users">
                    <Analtyics style={{ width: "100%", backgroundColor: "rgb(211 164 74)" }}>
                      <div style={{ fontSize: "14px", fontWeight: "400", color: "white" }}><i style={{ color: "white", fontSize: "30px" }} className="fa-solid fa-calendar-days"></i>&nbsp;&nbsp;New Registrations (last 30 days)</div>
                      <div style={{ fontSize: "24px", fontWeight: "400", color: "white" }}>{ana.data_Days}</div>
                    </Analtyics>
                  </NavLink>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <NavLink to="/admin/users">
                    <Analtyics style={{ width: "100%", backgroundColor: "#F77F00" }}>
                      <div style={{ fontSize: "14px", fontWeight: "400", color: "white" }}><i style={{ color: "white", fontSize: "30px" }} className="fa-solid fa-calendar-days"></i>&nbsp;&nbsp;New Registrations (last 6 Months)</div>
                      <div style={{ fontSize: "24px", fontWeight: "400", color: "white" }}>{ana.data_Six}</div>
                    </Analtyics>
                  </NavLink>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <NavLink to="/admin/users">
                    <Analtyics style={{ width: "100%", backgroundColor: "#003049" }}>
                      <div style={{ fontSize: "14px", fontWeight: "400", color: "white" }}><i style={{ color: "white", fontSize: "30px" }} className="fa-solid fa-users"></i>&nbsp;&nbsp;Total Users</div>
                      <div style={{ fontSize: "24px", fontWeight: "400", color: "white" }}>{usersCount}</div>
                    </Analtyics>
                  </NavLink>
                </Grid>
              </Grid>
            </>}
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Line options={options} data={data} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Line options={options} data={data2} />
            </Grid>
          </Grid>
        </BackPaper>
      </>
      }
    </>
  )
}

export default Analytic