import React, { useEffect, useState } from 'react'
import { AppRegistration, DeleteOutline, DriveFolderUpload, NoteAdd, RemoveRedEye } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Grid, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import APIFunctions from '../../ApiFunction'


const BackPaper =  styled(Paper)({
  height:"580px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Faq = () => {
  const [entry,setEntry] = useState(false)
  const [all,setAll]= useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [obj,setObj] = useState({name:'',item_image:''})
  const [deleteopen, setDelOpen] = React.useState(false);
  const navigate = useNavigate();
  const [id,setId] = useState()


  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };

  
  const getAll = async ()=>{
    try{
      let result = await APIFunctions.allFaq()
      if(result.data.code===200){
        setAll(result.data.data)
      }else if(result.data.code===401){
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate("/login")
        toast.info("Session expired")
      }
    }catch(err){
      console.error(err.message)
    }
  } 
  useEffect(()=>{
    setIsLoading(false)
    getAll()
  },[])

 

const deleteOne = async () =>{
  try{
    let result = await APIFunctions.deleteFaq(id)
    if(result.data.code===200){
      toast.success("Successfully deleted")
      setDelOpen(false);
      getAll();
    }else if(result.data.code===401){
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/login")
      toast.info("Session expired")
    }
  }catch(err){
    console.error(err.message)
  }
}

  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid  container  direction="row"   justifyContent="space-between" alignItems="center">
        <div></div>
         <div>
         <Grid container spacing={3} direction="row"  justifyContent="flex-start" alignItems="center">
          <Grid item><button className='newBtn' onClick={()=>navigate("/admin/add-new-faq")}>Add New</button></Grid>  
         </Grid>
         </div>   
    </Grid>
    </NavPaper>
    
    <BackPaper>
    {all.length===0?
      <Grid container spacing={2} style={{marginTop:"30px"}} direction="column" justifyContent="space-evenly" alignItems="center">
      <Grid item><img src="/images/no-data.png" alt="error-image" style={{height:"150px",width:"200px"}}/></Grid>
      <Grid item><div className="errorMessage">Data not found!</div></Grid>
    </Grid>:
      <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow className='tableHead'>
            <TableCell>Index</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Message</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {all.map((element,index)=>{
            let date, datearr, newDate;
            try {
              date = new Date(element.createdAt);
              datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
              newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                date.toISOString().substring(0, 10).split("-")[0];
            } catch (err) {
              console.error(err);
              // console.log('element.createdAt', element.createdAt);
              newDate = 'InvalidDate';
            }
            return(
          <TableRow key={index+1} className={index % 2 === 0 ? 'rowBg' : null}>
            <TableCell>{index+1}</TableCell>
            <TableCell>
              {element.title.length > 47 ?
                `${element.title.slice(0,47)}...`
                :
                element.title
              }
            </TableCell>
            <TableCell>
              {element.message.length > 247 ?
                `${element.message.slice(0,247)}...`
                :
                element.message
              }
            </TableCell>
            <TableCell align="center">{newDate}</TableCell>
            <Grid  container spacing={2} style={{marginTop:"10px"}}direction="row" justifyContent="center" alignItems="center">
               <Grid item>
                 <Tooltip title="View FAQ detail" >
                 <NavLink to='/admin/faq/details' state={{ id: element._id }}><RemoveRedEye className='visibilityIc' /></NavLink>
                 </Tooltip>
               </Grid>
               
               <Grid item>
                 <Tooltip title="Delete FAQ" >
                  <DeleteOutline className="userListDelete" onClick={()=>{handleDeleteOpen(element._id)}} />
                 </Tooltip>
               </Grid>
              <Grid item></Grid>
              </Grid>
          </TableRow>
            )
        })}    
        </TableBody>
      </Table>
      <Dialog open={deleteopen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this FAQ?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteOne(id)}} autoFocus>Delete</Button>
              </DialogActions>
            </Dialog>
      </>}
    
    </BackPaper>
    </>
    }
   
    </>
  )
}

export default Faq