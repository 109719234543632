import API from './Api'
import axios from 'axios'

const APIFunctions = {
    login: (data) => {
        return axios.post(`${API.LOGIN}`, data,)
    },
    forgotPassword: (data) => {
        return axios.post(`${API.FORGOT_PASSWORD}`, data,)
    },
    logout: () => {
        return axios.post(`${API.LOGOUT}`, {}, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    changePass: (obj) => {
        return axios.post(`${API.CHANGE_PASS}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    details: () => {
        return axios.get(`${API.DETAILS}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    edit_admin: (formData) => {
        return axios.post(`${API.EDIT_ADMIN}`, formData, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    createUser: (data) => {
        return axios.post(`${API.CREATE_USER}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAlluser: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_USERS}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllCreatedAdmin: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_CREATED_ADMIN}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllSubAdminUser: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_SUB_ADMIN}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getPremiumUser: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_PREMIUM_USERS}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    blockUser: (id) => {
        return axios.post(`${API.BLOCK_USER}${id}`, {}, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    removeUser: (id) => {
        return axios.post(`${API.REMOVE_USER}${id}`, {}, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    countUser: () => {
        return axios.get(`${API.COUNT}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    analytics: () => {
        return axios.get(`${API.ANALYTICS}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getRemoveUser: (key, row, skip) => {
        return axios.get(`${API.GET_REMOVE_USERS}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getUserDeatil: (id) => {
        return axios.get(`${API.GET_USER_DETAIL}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    addToWeb: (id) => {
        return axios.delete(`${API.ADD_TO_WEBSITE}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    edit_user: (id, formData) => {
        return axios.post(`${API.EDIT_USER}${id}`, formData, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    createAssistant: (data) => {
        return axios.post(`${API.CREATE_ASSISTANT}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllassistant: () => {
        return axios.get(`${API.GET_ALL_ASSISTANT}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAssistant: (id) => {
        return axios.get(`${API.GET_ASSISTANT}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editAssistant: (id, obj) => {
        return axios.post(`${API.EDIT_ASSISTANT}${id}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    createTopic: (obj) => {
        return axios.post(`${API.CREATE_TOPIC}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allTopic: () => {
        return axios.get(`${API.ALL_TOPIC}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    hideTopic: (id) => {
        return axios.post(`${API.HIDE_TOPIC}${id}`, {}, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteTopic: (id) => {
        return axios.delete(`${API.DELETE_TOPIC}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    createFaq: (obj) => {
        return axios.post(`${API.CREATE_FAQ}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allFaq: () => {
        return axios.get(`${API.ALL_FAQ}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    faq: (id) => {
        return axios.get(`${API.FAQ}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editFaq: (id, obj) => {
        return axios.post(`${API.EDIT_FAQ}${id}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteFaq: (id) => {
        return axios.delete(`${API.DELETE_FAQ}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    createArticle: (formData) => {
        return axios.post(`${API.CREATE_ARTICLE}`, formData, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allArticle: () => {
        return axios.get(`${API.ALL_ARTICLE}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    article: (id) => {
        return axios.get(`${API.ARTICLE}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editArticle: (id, formData) => {
        return axios.post(`${API.EDIT_ARTICLE}${id}`, formData, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    hideArticle: (id) => {
        return axios.post(`${API.TOGGLE_PUBLISH_ARTICLE}${id}`, {}, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteArticle: (id) => {
        return axios.delete(`${API.DELETE_ARTICLE}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allContact: () => {
        return axios.get(`${API.ALL_CONTACT}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    contact: (id) => {
        return axios.get(`${API.CONTACT}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allUserPost: (row, skip, filterPost) => {
        return axios.get(`${API.GET_ALL_POST}?row=${row}&skip=${skip}&filterPost=${filterPost}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editPost: (id, data) => {
        return axios.post(`${API.EDIT_POST}${id}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    postDetails: (id) => {
        return axios.get(`${API.GET_POST_DETAIL}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteUserPost: (id) => {
        return axios.delete(`${API.DELETE_POST}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allReportPost: (row, skip) => {
        return axios.get(`${API.All_REPORTED_POST}?row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    ReportedPostDetails: (id) => {
        return axios.get(`${API.REPORTED_POST_DETAIL}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteReportedPost: (id) => {
        return axios.delete(`${API.DELETE_REPORTED_POST}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    allReportedComment: (row, skip) => {
        return axios.get(`${API.All_REPORTED_COMMENT}?row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteReportedComment: (id) => {
        return axios.delete(`${API.DELETE_REPORTED_COMMENT}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllReportedProfiles: (row,skip) => {
        return axios.get(`${API.GET_ALL_REPORTED_PROFILES}?row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    creatCarModel: (data) => {
        return axios.post(`${API.CREATE_CAR_MODEL}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllCarModel: () => {
        return axios.get(`${API.ALL_CAR_MODEL}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteCarModel: (id) => {
        return axios.delete(`${API.DELETE_CAR_MODEL}${id}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getPrivacy: () => {
        return axios.get(`${API.GET_PRIVACY}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editPrivacy: (id, data) => {
        return axios.post(`${API.EDIT_PRIVACY}${id}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getTerms: () => {
        return axios.get(`${API.GET_TERMS}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editTerms: (id, data) => {
        return axios.post(`${API.EDIT_TERMS}${id}`, data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getPurchase: (search,row,skip) => {
        return axios.get(`${API.GET_PURCHASE}?search=${search}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getMembershipUser: (search) => {
        return axios.get(`${API.GET_MEMBERSHIP_USER}?search=${search}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllPricing: () => {
        return axios.get(`${API.GET_ALL_PRICING}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    editPricing: (data) => {
        return axios.post(`${API.EDIT_PRICING}`,data, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllGroups: (row, skip) => {
        return axios.get(`${API.GET_ALL_GROUPS}?row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },

    getGroupDetails: (obj) => {
        return axios.post(`${API.GET_GROUP_DETAILS}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteGroup: (obj) => {
        return axios.post(`${API.DELETE_GROUP}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteGroupPost: (obj) => {
        return axios.post(`${API.DELETE_GROUP_POST}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },

    getAllDirectories: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_DIRECTORIES}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllArchivedDirectories: (key, row, skip) => {
        return axios.get(`${API.GET_ALL_ARCHVIED_BUSINESS}?value=${key}&row=${row}&skip=${skip}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    viewDirectoryDetails: (obj) => {
        return axios.post(`${API.VIEW_DETAILS}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    getAllDraftBusiness: () => {
        return axios.get(`${API.GET_ALL_BUSINESS_DRAFT}`, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    archivedBusiness: (obj) => {
        return axios.post(`${API.ARCHIVED_BUSINESS}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },
    deleteDraftBusiness: (obj) => {
        return axios.post(`${API.DELETE_DRAFT_BUSINESS}`, obj, {
            headers: {
                'admintoken': localStorage.getItem('admintoken')
            }
        })
    },

}

export default APIFunctions


