import { RemoveCircleOutline, RemoveRedEye, } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'
import moment from 'moment'


const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Post = () => {
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState('10');
  const [postCount, setPostCount] = useState()
  const [skip, setSkip] = useState('0')
  const [filterPost, setFilterPost] = useState('')
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()








  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const filterPostHandle = (e) => {
    setFilterPost(e.target.value)
  }





  const getAll = async () => {
    setIsLoading(true)
    const result = await APIFunction.allUserPost(row, skip, filterPost);
    if (result.data.code === 200) {
      setAll(result.data.data)
      setPostCount(result.data.countPost)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    getAll()
    if (row > 10 || skip > 0 || filterPost != "") {
      setIsLoading(false)
    }
    if (filterPost != "") {
      setRow(postCount)
    }
  }, [row, skip, filterPost])

  const deletUserPost = async () => {
    const result = await APIFunction.deleteUserPost(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      setIsLoading(false)
      toast.success("Delete Successfully!")
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }

  const handleChange = (e) => {
    if (e.target.value === postCount) {
      setSkip(0)
      setRow(postCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Short post by:
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={filterPost}
                        onChange={filterPostHandle}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="recent">Most Recent</MenuItem>
                        <MenuItem value="regular">Regular Post</MenuItem>
                        <MenuItem value="featured">Featured Post</MenuItem>
                      </Select>
                    </FormControl>
                  </div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Posts: {all.length}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    {filterPost!=""?
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard-label"
                      value={row}
                      onChange={handleChange}
                    >
                      <MenuItem value={postCount}>All</MenuItem>
                    </Select>
                  </FormControl>:
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value={postCount}>All</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>}
                  </Grid>


                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {postCount === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">not Post yet!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell >Model</TableCell>
                        <TableCell >Year</TableCell>
                        <TableCell >Title</TableCell>
                        <TableCell >Type</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.image !== '' ? (
                                <img src={`${BASE_URL}uploads/images/posts/${element.image}`} style={{ width: "70px", height: "45px" }} alt="" />
                              ) : (
                                <img src="/images/noImage.jpg" style={{ width: "70px", height: "45px" }} alt="" />

                              )}
                            </TableCell>
                            <TableCell>{element.carName}</TableCell>
                            <TableCell>{element.carModel}</TableCell>
                            <TableCell>{element.title}</TableCell>
                            <TableCell>
                              {element.type ?
                                <div className='featuredPostStatus'>
                                  <div><i className="fa-solid fa-dollar-sign" style={{ fontSize: "15px" }}></i></div>
                                  <div>Featured Post</div>
                                </div> :
                                <div className='normalPostStatus'>
                                  <div><i className="fa-solid fa-signs-post" style={{ fontSize: "15px" }}></i></div>
                                  <div>Normal Post</div>
                                </div>}
                            </TableCell>
                            <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to='/admin/users-posts/post-details' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title="Remove Post From Website" >
                                    <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }


              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to delete this post from website?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={deletUserPost} >Yes</Button>
                </DialogActions>
              </Dialog>


            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(postCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}

    </>
  )
}

export default Post









