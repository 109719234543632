import { AccountCircle, AccountCircleOutlined, AddAPhoto, AddToPhotos, Build, BuildOutlined, CarRepair, Close, ConstructionOutlined, Email, EmailOutlined, Error, FreeBreakfastSharp, Info, OpenInNew, PersonOutlineOutlined, ScoreboardOutlined, WorkspacePremium, WorkspacePremiumOutlined } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import "./userDetail.css"
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'
import { BackPaper, BusinessLikesFoll, BusinessLink, BusinessName, DetailData, DetailLabel, DetailText } from '../../components/Styles'
import moment from 'moment'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UserDetail = ({ objs }) => {
  const router = useParams()
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [edit, setEdit] = useState(false)
  const [image, setImage] = useState("")
  const [image2, setImage2] = useState(null)
  const [obj, setObj] = useState({ name: "", email: "", role: "", mechanic_knowledge: "", general_car_knowledge: "" })
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [open, setOpen] = React.useState(false);

  const imageChange = (e) => {
    setImage2(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]))
  }

  const handlecChange = () => {
    setEdit(true)
    setOpen(true);
  }



  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }


  const getAll = async () => {
    const result = await APIFunctions.getUserDeatil(router.id);
    if (result.data.code === 200) {
      setObj(result.data.data)
      await delay(1000);
      setIsLoading(false)
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 203) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }

  const BlockUser = async (id) => {
    const result = await APIFunctions.blockUser(id);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }


  const update = async () => {
    let id = router.id
    const formData = new FormData()
    formData.append("name", obj.name)
    formData.append("role", obj.role)
    formData.append("email", obj.email)
    formData.append("mechanic_knowledge", obj.mechanic_knowledge)
    formData.append("general_car_knowledge", obj.general_car_knowledge)
    formData.append("profilePic", image2)
    const result = await APIFunctions.edit_user(id, formData);
    if (!result.data.status) {
      if (result.data.code === 205) {
        setMessage(result.data.message)
      } else if (result.data.code === 401) {
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate('/admin')
        toast.info("Session expired")
      }
      toast.error(result.data.message)
    } else {
      setIsLoading(false)
      setEdit(false)
      setOpen(false)
      setImage('')
      setImage2(null)
      getAll()
      setMessage('')
      toast.success("Profile has been updated successfully!")
    }
  }

  const discardChanges = () => {
    setOpen(false)
    setEdit(false)
    getAll()
    setImage('')
    setImage2(null)
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setImage('')
    setImage2(null)
  }
  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <>
          <Toptag />
          <ToastContainer />
          <BackPaper>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <div className='detailHeading'>Account Information</div>
              <div className='userDate'>This Profile is created on {moment(obj.createdAt).format('LLL')}</div>
            </div>
            <div className='d-flex p-2  flex-row gap-3 justify-content-start align-items-center'>
              {obj.profilePic !== '' ? (
                <>
                  {obj.profilePic && obj.profilePic.startsWith("https") ?
                    <img src={obj.profilePic} alt="user" className='user-detailsImage' />
                    :
                    <img src={`${BASE_URL}uploads/images/profiles/${obj.profilePic}`} alt="user" className='user-detailsImage' />
                  }
                </>
              ) : (
                <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

              )}
              <div className='d-flex flex-column gap-2 justify-content-start align-items-start'>
                <BusinessName>{obj.name} {obj.premiumStatus ? <Tooltip title="Premium User"><img src="/images/emailVerifiedIcon.svg" alt="emailVerifiedIcon.svg" /></Tooltip> : null} {!obj.isActive?<i style={{color:"red"}}className="fa-solid fa-ban"></i>:null}</BusinessName>
                <BusinessLink><Email style={{ fontSize: "13px" }} />{obj.email}</BusinessLink>
                <div className='d-flex flex-row gap-2 justify-content-start align-items-start'>
                  <BusinessLikesFoll>Points {obj.points}</BusinessLikesFoll>
                  <BusinessLikesFoll>Followings {obj.following && obj.following.length}</BusinessLikesFoll>
                  <BusinessLikesFoll>Followers {obj.followers && obj.followers.length}</BusinessLikesFoll>
                </div>
              </div>
            </div>
            <div className='w-100 text-end d-flex gap-4 justify-content-end'>
              {obj.isActive ?(
                <button className='newBtn' onClick={() => { BlockUser(obj._id) }}>Block User</button>)
                :
                (<button className='newBtn' onClick={() => { BlockUser(obj._id) }}>UnBlock User</button>)
              }
              {!edit ?
                <button className='newBtn' onClick={handlecChange}>Edit Profile</button>
                :
                null
              }
            </div>
            <hr />
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ borderRight: "2px solid lightgrey" }} >
                  <Grid item>
                    <DetailLabel><Info className='detailIcon' />Bio</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <DetailText>{obj.bio}</DetailText>
                  </Grid>
                  <Grid item>
                    <DetailLabel><Build className='detailIcon' />Mechanic Knowledge</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <DetailText>{obj.mechanic_knowledge}</DetailText>
                  </Grid>
                  <Grid item>
                    <DetailLabel><CarRepair className='detailIcon' />General Car Knowledge</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <DetailText>{obj.general_car_knowledge}</DetailText>
                  </Grid>
                </Grid>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8'>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item>
                    <DetailLabel><AccountCircleOutlined className='detailIcon' /> Name:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.name} className="detailBarInput" />
                  </Grid>
                  <Grid item>
                    <DetailLabel><EmailOutlined className='detailIcon' /> Email:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.email} className="detailBarInput" />
                  </Grid>
                  <Grid item>
                    <DetailLabel><ScoreboardOutlined className='detailIcon' /> Points:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.points} className="detailBarInput" />
                  </Grid>
                  <Grid item>
                    <DetailLabel><PersonOutlineOutlined className='detailIcon' /> Role:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.role} className="detailBarInput" />
                  </Grid>
                  <Grid item>
                    <DetailLabel><BuildOutlined className='detailIcon' /> Mechanic Knowledge:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.mechanic_knowledge} className="detailBarInput" />
                  </Grid>
                  <Grid item>
                    <DetailLabel><ConstructionOutlined className='detailIcon' /> General Car Knowledge:</DetailLabel>
                  </Grid>
                  <Grid item className='w-100'>
                    <input type="text" disabled value={obj.general_car_knowledge} className="detailBarInput" />
                  </Grid>

                </Grid>
              </div>
            </div>
            <BootstrapDialog
              fullWidth={true}
              aria-labelledby="customized-dialog-title"
              open={open}
              maxWidth={'sm'}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Edit Profile
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={6} lg={5} item >
                    <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        {image !== "" ?
                          <img src={image} className='user-detailsImage' alt="" />
                          :
                          <>
                            {obj.profilePic !== '' ? (
                              <>
                                {obj.profilePic && obj.profilePic.startsWith("https") ?
                                  <img src={obj.profilePic} alt="user" className='user-detailsImage' />
                                  :
                                  <img src={`${BASE_URL}uploads/images/profiles/${obj.profilePic}`} alt="user" className='user-detailsImage' />
                                }
                              </>
                            ) : (
                              <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                            )}
                          </>}
                      </Grid>
                      <Grid item>
                        <label htmlFor='uploadImage'>
                          <Tooltip title="click to change image">
                            <AddAPhoto className='imageUploadIcon' />
                          </Tooltip>
                        </label>
                        <input id='uploadImage' onChange={imageChange} accept='image/*' type='file' style={{ display: "none" }} />
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} lg={7} item>
                    <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                      <Grid item>Name:</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" disabled={!edit ? true : false} value={obj.name} name="name" id='name' onChange={addata} className="detailBarInput" style={{ border: message !== '' && message.startsWith('name') ? "1px solid red" : null }} />
                      </Grid>
                      <Grid item>Email:</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" disabled={true} value={obj.email} className="detailBarInput" name='email' id='email' onChange={addata} style={{ border: message !== '' && message.startsWith('email') ? "1px solid red" : null }} />
                      </Grid>
                      <Grid item>Role:</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <select disabled={!edit ? true : false} value={obj.role} className="detailBarInput" name='role' id='role' onChange={addata} style={{ border: message !== '' && message.startsWith('role') ? "1px solid red" : null }}>
                          <option value="">Select Role:-</option>
                          <option value="user">User</option>
                          <option value="sub-admin">Admin</option>
                        </select>
                      </Grid>
                      <Grid item>Mechanic Knowledge:</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <select disabled={!edit ? true : false} value={obj.mechanic_knowledge} name="mechanic_knowledge" id="mechanic_knowledge" className="detailBarInput" onChange={addata} style={{ border: message !== '' && message.startsWith('mechanic_knowledge') ? "1px solid red" : null }}>
                          <option value="">Select Level:-</option>
                          <option value="beginner">Beginner</option>
                          <option value="internediate">Intermediate</option>
                          <option value="expert">Expert</option>
                        </select>
                      </Grid>
                      <Grid item>General Car Knowledge:</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <select disabled={!edit ? true : false} value={obj.general_car_knowledge} name="general_car_knowledge" id="general_car_knowledge" className="detailBarInput" onChange={addata} style={{ border: message !== '' && message.startsWith('general_car_knowledge') ? "1px solid red" : null }}>
                          <option value="">Select Level:-</option>
                          <option value="beginner">Beginner</option>
                          <option value="internediate">Intermediate</option>
                          <option value="expert">Expert</option>
                        </select>
                      </Grid>
                      <Grid item>
                        <div className='errText'>
                          {message !== '' ? <div className='errText'><Error style={{ fontSize: "18px" }} /> All feilds are required!</div> : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </DialogContent>
              <DialogActions>
                <buttin className="newBtn2" onClick={discardChanges}>
                  Discard changes
                </buttin>
                <buttin className="newBtn" autoFocus onClick={update}>
                  Save
                </buttin>
              </DialogActions>
            </BootstrapDialog>
          </BackPaper>
        </>}

    </>
  )
}

export default UserDetail