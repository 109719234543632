import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, VisibilityOff, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ModelCr = () => {
    const [all, setAll] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [remopen, setRemOpen] = React.useState(false);
    const [id, setId] = useState()
    const [status, setStatus] = useState()
    const [err, setErr] = useState(false)
    const [create, setCreate] = useState(false);
    const [obj, setObj] = useState({
        make: "",
        name: "",
    })

    const addata = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value.trim(),
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (obj.make === '' || obj.name === '') {
            setErr(true);
        } else {
            setErr(false);
            const result = await APIFunction.creatCarModel(obj);
            if (result.data.code === 200) {
                setCreate(false)
                toast.success("Model created succesfully!")
                getAll()
            } else if (result.data.code === 201) {
                toast.error("Topic already exist!")
            } else if (result.data.code === 401) {
                localStorage.clear('admintoken');
                sessionStorage.clear('admintoken');
                navigate('/admin')
                toast.info("Session expired")
            }
        }
    };






    const handleDeleteOpen = (id) => {
        setId(id)
        setRemOpen(true);
    };

    const handleDelete = () => {
        setRemOpen(false);
    };





    const handleUserOpen = () => {
        setCreate(true);
    };



    const getAll = async () => {
        const result = await APIFunction.getAllCarModel();
        if (result.data.code === 200) {
            setAll(result.data.data)
        } else if (result.data.code === 401) {
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
        }
    }
    useEffect(() => {
        getAll()
    }, [])


    const deleteModel = async () => {
        const result = await APIFunction.deleteCarModel(id);
        if (result.data.code === 200) {
            getAll();
            setRemOpen(false);
            toast.success("Deleted Successfully")
        } else if (result.data.code === 401) {
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
        }
    }

    return (
        <>


            {
                isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <ToastContainer />
                        <NavPaper>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div></div>
                                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        <button className='newBtn' onClick={handleUserOpen}>ADD NEW MODEL</button>
                                    </Grid>
                                </Grid>

                            </div>
                        </NavPaper>
                        <BackPaper>
                            {all.length === 0 ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">Data not found!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Index</TableCell>
                                                <TableCell >Car Make</TableCell>
                                                <TableCell >Car Model</TableCell>
                                                <TableCell>Created On</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {all.map((element, index) => {
                                                let date, datearr, newDate;
                                                try {
                                                  date = new Date(element.createdAt);
                                                  datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                                                  newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                                                    datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                                                    date.toISOString().substring(0, 10).split("-")[0];
                                                } catch (err) {
                                                  console.error(err);
                                                  // console.log('element.createdAt', element.createdAt);
                                                  newDate = 'InvalidDate';
                                                }
                                                return (<>
                                                    <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{element.make}</TableCell>
                                                        <TableCell>{element.name}</TableCell>
                                                        <TableCell>{newDate}</TableCell>
                                                        <TableCell align="right">
                                                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Tooltip title="Delete Model From Website" >
                                                                        <DeleteOutline className="userListDelete" onClick={() => { handleDeleteOpen(element._id) }} />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item></Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>

                                </>
                            }


                            {/* dilogue for delete topic  */}
                            <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {"Are you sure want to delete this car model from website?"}
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleDelete}>No</Button>
                                    <Button onClick={() => { deleteModel(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>

                            {/* dilogue for create user */}
                            <Dialog open={create} aria-labelledby="responsive-dialog-title">
                                <div className="popUpCreate">
                                    <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                                        <Cancel style={{ fontSize: "25px", color: "white", cursor: "pointer" }} onClick={() => setCreate(false)} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className='popHeading'>Create Car Model For CARFO</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                                <div className="popText">Make</div>
                                                <div>
                                                    <input type="text" className="popInput" name="make" id="make" onChange={addata} />
                                                </div>
                                                <div className="popText">Model</div>
                                                <div>
                                                    <input type="text" className="popInput" name="name" id="name" onChange={addata} />
                                                </div>
                                                {err && (obj.make === '' || obj.name === '') ?
                                                    <div className="errText">Make and Name are required</div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                                        <div>
                                            <button className='popBtn' onClick={handleSubmit}>Create</button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </BackPaper>
                    </>

                )}

        </>
    )
}

export default ModelCr









