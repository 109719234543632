import { Paper, Typography, styled } from "@mui/material";

export const BackPaper = styled(Paper)({
    height: "100vh",
    borderRadius: "0",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
})


export const NavPaper = styled(Paper)({
    padding: "7px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
  })
  

export const DetailLabel = styled(Typography)({
    fontSize: "14px",
    color: "#000814",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontFamily:'system-ui',
    fontWeight: "bold"
})

export const DetailData = styled(Typography)({
    fontSize: "15px",
    color: "#000000",
    fontWeight: "500",
    fontFamily:'system-ui',
})


export const DetailText = styled(Typography)({
    fontSize: "15px",
    color: "#515050",
    display: "flex",
    paddingLeft:"28px",
    fontFamily:'system-ui',
    marginBottom:"18px",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontWeight: "400"
})

export const BusinessName = styled(Typography)({
    fontSize: "18px",
    color: "#000814",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily:'system-ui',
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold"
})

export const BusinessLink = styled(Typography)({
    fontSize: "13px",
    color: "#0466c8",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily:'system-ui',
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold"
})

export const BusinessLikesFoll = styled(Typography)({
    fontSize: "12px",
    color: "#495057",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily:'system-ui',
    gap: "8px",
    fontWeight: "bold"
})