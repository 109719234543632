import { AccountCircle, AccountCircleOutlined, AddAPhoto, AddToPhotos, Build, BuildOutlined, CarRepair, Close, ConstructionOutlined, Email, EmailOutlined, Error, FreeBreakfastSharp, Info, OpenInNew, PersonOutlineOutlined, ScoreboardOutlined, WorkspacePremium, WorkspacePremiumOutlined } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'
import { BackPaper, BusinessLikesFoll, BusinessLink, BusinessName, DetailData, DetailLabel, DetailText } from '../../components/Styles'
import moment from 'moment'
import { setUser } from '../../slice/userInfo'
import { useDispatch } from 'react-redux'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export default function Profile() {
  const navigate=useNavigate();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [detail,setDetail]=useState({name:'',email:''})
  const [image,setImage]=useState("")
  const [error, setError]=useState(false)
  const [image2,setImage2]=useState("")
  const [data, setData]=useState([])
  const [message, setMessage] = useState('');
  const dispatch = useDispatch()

  const handleChange = () => {
    setEdit(true)
  }

 

 const inputEvent = (e) =>{
  setDetail({
    ...detail,
    [e.target.name] : e.target.value.trim(),
 })
 }

 const handleClose = () => {
  setEdit(false)
  setImage('')
  setImage2(null)
}
  const imageChange=(e)=>{
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    if (file.type==='image/jpeg' || file.type==='image/png') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }else if (file.type!='image/jpeg' || file.type!='image/png'){
      setImage2("")
      setError(true)
    }else{
      setError(true)
    }
    
  }
  useEffect(() => {
    setIsLoading(true);
    getData()
  },[])

  const getData = async() => {
    const result = await APIFunctions.details()
      if(result.data.code===200){
          setIsLoading(false)
          dispatch(setUser(result.data.data))
          localStorage.setItem('user',JSON.stringify(result.data.data))
          setDetail(result.data.data)
          }else if(result.data.code===401){
              localStorage.clear('admintoken');
              sessionStorage.clear('admintoken');
              navigate('/admin')
              toast.info("Session expired")
            } 
    
  }
  const updateAdminProfile = async (event) => {
    localStorage.removeItem('image')
    event.preventDefault();
    const formData = new FormData()
    formData.append("name",detail.name)
    formData.append("email",detail.email)
    formData.append("profilePic",image2!==null?image2:detail.profilePic)
      const result = await APIFunctions.edit_admin(formData);
      if(result.data.code===200){
          setIsLoading(false)   
          toast.success("Updated successfully!")  
          getData();
          setEdit(false)
        }else if(result.data.code===201){
            toast.error("error are coming for fetching images")
          }else if(result.data.code===401){
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
          }
  }

  return (
    <>
    {isLoading ?
      <Loader />
      :
      <>
        <Toptag />
        <ToastContainer />
        <BackPaper>
          <div className='d-flex justify-content-between align-items-center p-2'>
            <div className='detailHeading'>Hello,{detail.role==='Admin'?'Admin': 'You are sub admin now'}</div>
          </div>
          <div className='d-flex p-2  flex-row gap-3 justify-content-start align-items-center'>
            {detail.profilePic !== '' ? (
              <>
                {detail.profilePic && detail.profilePic.startsWith("https") ?
                  <img src={detail.profilePic} alt="user" className='user-detailsImage' />
                  :
                  <img src={`${BASE_URL}uploads/images/profiles/${detail.profilePic}`} alt="user" className='user-detailsImage' />
                }
              </>
            ) : (
              <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

            )}
            <div className='d-flex flex-column gap-2 justify-content-start align-items-start'>
              <BusinessName>{detail.name} {detail.premiumStatus ? <Tooltip title="Premium User"><img src="/images/emailVerifiedIcon.svg" alt="emailVerifiedIcon.svg" /></Tooltip> : null}</BusinessName>
              <BusinessLink><Email style={{ fontSize: "13px" }} />{detail.email}</BusinessLink>
              <div className='d-flex flex-row gap-2 justify-content-start align-items-start'>
                <BusinessLikesFoll>Points {detail.points}</BusinessLikesFoll>
                <BusinessLikesFoll>Followings {detail.following && detail.following.length}</BusinessLikesFoll>
                <BusinessLikesFoll>Followers {detail.followers && detail.followers.length}</BusinessLikesFoll>
              </div>
            </div>
          </div>
          <div className='w-100 text-end'>
            {!edit ?
              <button className='newBtn' onClick={handleChange}>Edit Admin</button>
              :
              null
            }
          </div>
            <hr />
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ borderRight: "2px solid lightgrey" }} >
                <Grid item>
                  <DetailLabel><Info className='detailIcon' />Bio</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <DetailText>{detail.bio}</DetailText>
                </Grid>
                <Grid item>
                  <DetailLabel><Build className='detailIcon' />Mechanic Knowledge</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <DetailText>{detail.mechanic_knowledge}</DetailText>
                </Grid>
                <Grid item>
                  <DetailLabel><CarRepair className='detailIcon' />General Car Knowledge</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <DetailText>{detail.general_car_knowledge}</DetailText>
                </Grid>
              </Grid>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8'>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <DetailLabel><AccountCircleOutlined className='detailIcon' /> Name:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.name} className="detailBarInput" />
                </Grid>
                <Grid item>
                  <DetailLabel><EmailOutlined className='detailIcon' /> Email:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.email} className="detailBarInput" />
                </Grid>
                <Grid item>
                  <DetailLabel><ScoreboardOutlined className='detailIcon' /> Points:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.points} className="detailBarInput" />
                </Grid>
                <Grid item>
                  <DetailLabel><PersonOutlineOutlined className='detailIcon' /> Role:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.role} className="detailBarInput" />
                </Grid>
                <Grid item>
                  <DetailLabel><BuildOutlined className='detailIcon' /> Mechanic Knowledge:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.mechanic_knowledge} className="detailBarInput" />
                </Grid>
                <Grid item>
                  <DetailLabel><ConstructionOutlined className='detailIcon' /> General Car Knowledge:</DetailLabel>
                </Grid>
                <Grid item className='w-100'>
                  <input type="text" disabled value={detail.general_car_knowledge} className="detailBarInput" />
                </Grid>

              </Grid>
            </div>
          </div>
          <BootstrapDialog
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
            open={edit}
            maxWidth={'sm'}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Edit Admin Profile
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} lg={5} item >
                  <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      {image !== "" ?
                        <img src={image} className='user-detailsImage' alt="" />
                        :
                        <>
                          {detail.profilePic !== '' ? (
                            <>
                              {detail.profilePic && detail.profilePic.startsWith("https") ?
                                <img src={detail.profilePic} alt="user" className='user-detailsImage' />
                                :
                                <img src={`${BASE_URL}uploads/images/profiles/${detail.profilePic}`} alt="user" className='user-detailsImage' />
                              }
                            </>
                          ) : (
                            <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                          )}
                        </>}
                    </Grid>
                    <Grid item>
                      <label htmlFor='uploadImage'>
                        <Tooltip title="click to change image">
                          <AddAPhoto className='imageUploadIcon' />
                        </Tooltip>
                      </label>
                      <input id='uploadImage' onChange={imageChange} accept='image/*' type='file' style={{ display: "none" }} />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={7} item>
                  <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>Name:</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <input type="text" disabled={!edit ? true : false} value={detail.name} name="name" id='name' onChange={inputEvent} className="detailBarInput" style={{ border: message !== '' && message.startsWith('name') ? "1px solid red" : null }} />
                    </Grid>
                    <Grid item>Email:</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <input type="text" disabled value={detail.email} className="detailBarInput" name='email' id='email' onChange={inputEvent} style={{ border: message !== '' && message.startsWith('email') ? "1px solid red" : null }} />
                    </Grid>
                   {detail.role==='sub-admin'?null:
                   <>
                    <Grid item>Role:</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <select disabled={!edit ? true : false}  value={detail.role} className="detailBarInput" name='role' id='role' onChange={inputEvent} style={{ border: message !== '' && message.startsWith('role') ? "1px solid red" : null }}>
                        <option value="">Select Role:-</option>
                        <option value="user">User</option>
                        <option value="sub-admin">Admin</option>
                      </select>
                    </Grid>
                    <Grid item>Mechanic Knowledge:</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <select disabled={!edit ? true : false} value={detail.mechanic_knowledge} name="mechanic_knowledge" id="mechanic_knowledge" className="detailBarInput" onChange={inputEvent} style={{ border: message !== '' && message.startsWith('mechanic_knowledge') ? "1px solid red" : null }}>
                        <option value="">Select Level:-</option>
                        <option value="beginner">Beginner</option>
                        <option value="internediate">Intermediate</option>
                        <option value="expert">Expert</option>
                      </select>
                    </Grid>
                    <Grid item>General Car Knowledge:</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <select disabled={!edit ? true : false} value={detail.general_car_knowledge} name="general_car_knowledge" id="general_car_knowledge" className="detailBarInput" onChange={inputEvent} style={{ border: message !== '' && message.startsWith('general_car_knowledge') ? "1px solid red" : null }}>
                        <option value="">Select Level:-</option>
                        <option value="beginner">Beginner</option>
                        <option value="internediate">Intermediate</option>
                        <option value="expert">Expert</option>
                      </select>
                    </Grid>
                    </>}
                    <Grid item>
                      <div className='errText'>
                        {message !== '' ? <div className='errText'><Error style={{ fontSize: "18px" }} /> All feilds are required!</div> : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions>
              <buttin className="newBtn2" onClick={handleClose}>
                Discard changes
              </buttin>
              <buttin className="newBtn" autoFocus onClick={updateAdminProfile}>
                Save
              </buttin>
            </DialogActions>
          </BootstrapDialog>
        </BackPaper>
      </>}

  </>

  )
}
