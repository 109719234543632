import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Private = () => {
  var auth = localStorage.getItem("admintoken")
  console.log("Auth is ===> ", auth)
  return (
    <>
      {auth
        ?
        <Outlet />
        :
        <Navigate to='/login'></Navigate>
      }
    </>

  )
}

export default Private