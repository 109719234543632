import { RemoveCircleOutline, RemoveRedEye, } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'


const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ReportCom = () => {
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState('10');
  const [postCount, setPostCount] = useState()
  const [skip, setSkip] = useState('0')
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()








  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };







  const getAll = async () => {
    setIsLoading(true)
    const result = await APIFunction.allReportedComment(row, skip);
    if (result.data.code === 200) {
      setAll(result.data.data)
      setPostCount(result.data.countPost)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    getAll()
    if (row > 10 || skip > 0) {
      setIsLoading(false)
    }

  }, [row, skip])

  const deletUserPost = async () => {
    const result = await APIFunction.deleteReportedComment(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      setIsLoading(false)
      toast.success("Delete Successfully!")
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }

  const handleChange = (e) => {
    if (e.target.value === postCount) {
      setSkip(0)
      setRow(postCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Posts: {all.length}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value={postCount}>All</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>


                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ?(
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">No Reported Comment Yet!</div></Grid>
                </Grid>) :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>Reported By</TableCell>
                        <TableCell >Reported By Name</TableCell>
                        <TableCell >Comment</TableCell>
                        <TableCell >Reason</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell>Reported At</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        let date, datearr, newDate;
                        try {
                          date = new Date(element.createdAt);
                          datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                          newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                            datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                            date.toISOString().substring(0, 10).split("-")[0];
                        } catch (err) {
                          console.error(err);
                          // console.log('element.createdAt', element.createdAt);
                          newDate = 'InvalidDate';
                        }
                        return (<>
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.user_image !== '' ? (
                                <img src={`${element.user_image.startsWith("https") ? element.user_image : `${BASE_URL}uploads/images/profiles/` + element.user_image}`} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                              ) : (
                                <img src="/images/blank_pic.png" style={{ width: "40px", height: "40px", borderRadius: "50px" }} alt="" />

                              )}
                            </TableCell>
                            <TableCell>{element.user_name}</TableCell>
                            <TableCell>
                              {element.comment.slice(0, 40)}...&nbsp;&nbsp;
                              <Tooltip title={element.comment}><i className="fa-solid fa-binoculars" style={{fontSize:'20px',cursor:'pointer'}}></i>
                              </Tooltip>
                            </TableCell>
                            <TableCell>{element.reason}</TableCell>
                            <TableCell align="center">
                              <div className='removedUser-Access'>
                                <div><i class="fa-solid fa-bug-slash" style={{ fontSize: "18px" }}></i></div>
                                <div>Reported</div>
                              </div> </TableCell>
                            <TableCell>{newDate}</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="Remove Comment From Post" >
                                    <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element.commentId) }} />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }


              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to delete this comment from post?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={deletUserPost} >Yes</Button>
                </DialogActions>
              </Dialog>


            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(postCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}

    </>
  )
}

export default ReportCom









