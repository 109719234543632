import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'


const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ReportPostDetail = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const [obj, setObj] = useState({})




    const getAll = async () => {
        setIsLoading(true)
        const result = await APIFunctions.ReportedPostDetails(location.state.id);
        if (result.data.code === 200) {
            setIsLoading(false)
            setObj(result.data.data[0])
        } else if (result.data.code === 201) {
            toast.error("error are coming for fetching images")
        } else if (result.data.code === 203) {
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
        }
    }
    useEffect(() => {
        getAll()
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Post Details</Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        {obj.reportedPostImage !== '' ? (
                                            <img src={`${BASE_URL}uploads/${obj.reportedPostImage}`} className='detailPageImage' alt="" />
                                        ) : (
                                            <img src="/images/noImage.jpg" className='detailPageImage' alt="" />

                                        )}
                                    </Grid>
                                </Grid>
                            </Grid><Grid xs={12} sm={12} md={6} lg={7} item>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item>Reported by this user.</Grid>
                                    <Grid item>User Name</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.reportByUser} name="name" className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Reason</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.message} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Post owner and details</Grid>
                                    <Grid item>Title</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.reportedPostTitle} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Description</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <textarea type="text" disabled value={obj.reportedPostDescription} style={{ height: "200px" }} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Owner name</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.postOwnerName} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Owner email</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                    <input type="text" disabled value={obj.postOwnerEmail} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>User Status</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                    <input type="text" disabled value={obj.postOwnerPremium?'Premium User':'Freemium User'} className="detailBarInput" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </BackPaper>
                </>}
        </>
    )
}

export default ReportPostDetail


