import { Block, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunctions from '../../ApiFunction'
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'
// import "./user.css"






const Transaction = () => {
  const router = useLocation();
  const [all, setAll] = useState([]);
  let user = all.length
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [isLoading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState('0')
  const [search, setSearch] = useState('')

  

  const getAll = async () => {
    const result = await APIFunctions.getPurchase(search, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.data)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    getAll()
  }, [search, row, skip])


  const handleChange = (e) => {
    if (e.target.value === user) {
      setSkip(0)
      setRow(user)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }

  return (
    <>
      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Transaction: {all.length}</div></Grid>
                  {/* <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid> */}
                  {/* <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={(e) => setSearch(e.target.value)} className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Data not found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell >Transaction Date</TableCell>
                        <TableCell >User Name</TableCell>
                        <TableCell >User Email Address</TableCell>
                        <TableCell >Transaction ID</TableCell>
                        <TableCell>Transaction Amount</TableCell>
                        <TableCell>Purchase Category</TableCell>
                        <TableCell>Transaction Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell>{element.user_name}</TableCell>
                            <TableCell>{element.user_email}</TableCell>
                            <TableCell>{element.paymentId}</TableCell>
                            <TableCell align='center'>${element.amount}</TableCell>
                            <TableCell>
                              {element.isMembership ?
                                <div className='purchaseChip'>
                                  <img src="/images/membership_purchase.png" alt="membership_purchase" style={{ width: "25px", height: "25px", borderRadius: '50px' }} />
                                  <div>Membership Plan</div>
                                </div>
                                : element.isChatLimit ?
                                  <div className='purchaseChip'>
                                    <div />
                                    <div>Ask AI Queries</div>
                                  </div>
                                  : element.isFeaturedPost ?
                                    <div className='purchaseChip'>
                                      <img src="/images/featured_purchase.png" alt="membership_purchase" style={{ width: "25px", height: "25px", borderRadius: '50px' }} />
                                      <div>Featured Post</div>
                                    </div>
                                    : element.isBannerAds ?
                                      <div className='purchaseChip'>
                                        <img src="/images/bannerads_purchase.png" alt="membership_purchase" style={{ width: "25px", height: "25px", borderRadius: '50px' }} />
                                        <div>Banner Ads</div>
                                      </div> : null
                              }</TableCell>
                            <TableCell>
                              {element.paymentStatus ?
                                <Chip label="Completed" className="statusChipCompleted" />
                                : <Chip label="Failed" className="statusChipFailed" />
                              }</TableCell>

                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }

            </BackPaper>
            {/* <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(user / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div> */}
          </>

        )}
    </>
  )
}

export default Transaction