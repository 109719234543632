import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import moment from 'moment'


const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Assistant = () => {
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [key, setKey] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [err, setErr] = useState(false)
  const [create, setCreate] = useState(false);

  const [obj, setObj] = useState(
    { name: "", contact: "" }
  )

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (obj.name === '' || obj.contact === '' || isNaN(parseFloat(obj.contact))) {
      setErr(true);
    } else {
      const result = await APIFunction.createAssistant(obj);
      if (result.data.code === 200) {
        setCreate(false)
        getAll();
        toast.success("Contact created succesfully!")
      } else if (result.data.code === 201) {
        toast.success("User already exist!")
      } else if (result.data.code === 401) {
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate('/admin')
        toast.info("Session expired")
      }
    }
  };




  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleUserOpen = () => {
    setCreate(true);
  };


  const getAll = async () => {
    if (key) {
      setIsLoading(false)
    }
    const result = await APIFunction.getAllassistant();
    if (result.data.code === 200) {
      setAll(result.data.data)
      await delay(1000)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    setErr(false)
    getAll()
  }, [])





  return (
    <>


      {
        isLoading ? 
          <Loader />: 
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <button className='newBtn' onClick={handleUserOpen}>ADD CONTACT</button>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell >Name</TableCell>
                    <TableCell >Phone</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {all.map((element, index) => {
                    return (<>
                      <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{element.name}</TableCell>
                        <TableCell>{element.contact}</TableCell>
                        <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                        <TableCell align="right">
                          <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                            <Grid item>
                              <Tooltip title="View" >
                                <NavLink to='/admin/assistant-contacts/details' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </>
                    )

                  })}
                </TableBody>
              </Table>


              {/* dilogue for create assistant */}
              <Dialog open={create} aria-labelledby="responsive-dialog-title">
                <div className="popUpCreate">
                  <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                    <Cancel style={{ fontSize: "25px", color: "white", cursor: "pointer" }} onClick={() => setCreate(false)} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className='popHeading'>Create Contact</div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Name</div>
                        <div><input type="text" className="popInput" name="name" id="name" onChange={addata} /></div>
                        {err && obj.name === '' ? <div className="errText">Name is required!</div> : null}
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Contact</div>
                        <div><input type="text" className="popInput" name="contact" id="contact" onChange={addata} /></div>
                        {err && obj.contact === '' ? <div className="errText">Contact is required!</div> : null}
                        {err && obj.contact !== '' && isNaN(parseFloat(obj.contact)) ? <div className="errText">Invalid contact number!</div> : null}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                    <div>
                      <button className='popBtn' onClick={handleSubmit}>Create</button>
                    </div>
                  </div>
                </div>
              </Dialog>
            </BackPaper>
          </>

        }

    </>
  )
}

export default Assistant









