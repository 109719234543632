import { Archive, Block, Cancel, Delete, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'


const Draft = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [all, setAll] = useState([]);
    const navigate = useNavigate();
    const [deleteopen, setDeleteOpen] = useState(false);
    const [id, setId] = useState()







    const handleClodseOpen = (id) => {
        setId(id)
        setDeleteOpen(true);
    };

    const handleClodse = () => {
        setDeleteOpen(false);
    };



    const getAll = async () => {
        try {
            const result = await APIFunction.getAllDraftBusiness();
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/login')
                    toast.info("Session expired")
                }
                toast.error(result.data.message)
            } else {
                setAll(result.data.data)
                setIsLoading(false)
            }

        } catch (error) {
            console.log(error.message)
        }
    }


    const draftBusiness = async (id) => {
        try {
            let obj = {
                id: id
            }
            const result = await APIFunction.deleteDraftBusiness(obj);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/login')
                    toast.info("Session expired")
                }
                toast.error(result.data.message)
            } else {
                setDeleteOpen(false);
                getAll();
                toast.success("Draft has been delete successfully!")
            }

        } catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        getAll()
    }, [])





    return (
        <>


            {
                isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <ToastContainer />
                        <BackPaper>
                            {all.length === 0 ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">Not have any draft yet!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>Logo</TableCell>
                                                <TableCell >Business Name</TableCell>
                                                <TableCell >Business Email</TableCell>
                                                <TableCell>Created On</TableCell>
                                                <TableCell >Likes</TableCell>
                                                <TableCell>Verfication</TableCell>
                                                <TableCell>Draft</TableCell>
                                                {/* <TableCell >User Deletion</TableCell> */}
                                                <TableCell align='center'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {all.map((element, index) => {

                                                return (<>
                                                    <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            {element.business_logo !== '' ? (
                                                                <img src={`${BASE_URL + 'uploads/images/businessLogos/' + element.image}`} alt="user" style={{ width: "30px", height: "30px", borderRadius: '50px' }} />
                                                            ) : (
                                                                <img src="/images/blank_pic.png" style={{ width: "30px", height: "30px", borderRadius: "50px" }} alt="" />

                                                            )}
                                                        </TableCell>
                                                        <TableCell>{element.business_name}</TableCell>
                                                        <TableCell>{element.business_email}</TableCell>
                                                        <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                                                        <TableCell >
                                                            {element.likes && element.likes.length}
                                                        </TableCell>
                                                        <TableCell>
                                                            {element.isVerified ? (
                                                                <Chip label="Verified" className="statusChipActive" />)
                                                                : (
                                                                    <Chip label="Pending" className="statusChipPending" />)
                                                            }</TableCell>
                                                        <TableCell>
                                                            <div className='viewText'>
                                                                {element.isDraft ? 'Saved as Draft' : '...'}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Tooltip title="Delete Saved Draft" >
                                                                        <Delete className="userListDelete" onClick={() => { handleClodseOpen(element._id) }} />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>

                                </>
                            }


                            {/* dilogue for delete user */}
                            <Dialog open={deleteopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {"Delete the saved draft?"}
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Deleting this draft will not affect the user profile.
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClodse}>No</Button>
                                    <Button onClick={() => { draftBusiness(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>


                        </BackPaper>
                    </>

                )}

        </>
    )
}

export default Draft









