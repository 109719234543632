import React, { useState } from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import {
  DirectionsCar,
  KeyboardArrowRight,
  Dashboard,
  PeopleOutline,
  ReportGmailerrorredOutlined,
  ContactEmergencyOutlined,
  ArticleOutlined,
  PaidOutlined,
  MailOutlined,
  ContentPasteOutlined,
  Diversity1Outlined,
  StoreMallDirectoryOutlined,
  ArrowRightAltOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const user = useSelector((state) => state.userinfo.user);

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Main</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <Dashboard className="icon" />
              <span>DashBoard</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/home">
              <div>Home</div>
            </NavLink>
            <NavLink to="/admin/users-analytics">
              <div>Users Analytics</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        {user && user.role === "sub-admin" ? null : (
          <>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Users</h3>
              <div className="sidebarSelect">
                <div className="sidebarSelectHead">
                  <PeopleOutline className="icon" />
                  <span>Manage Users</span>
                </div>
              </div>
              <div className="sidebarSelectList">
                <NavLink to="/admin/users">
                  <div>Freemium Users</div>
                </NavLink>
                <NavLink to="/admin/removed-users">
                  <div>Archived Users </div>
                </NavLink>
                <NavLink to="/admin/premium-users">
                  <div>Premium Users</div>
                </NavLink>
              </div>
            </div>
            <hr></hr>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Users</h3>
              <div className="sidebarSelect">
                <div className="sidebarSelectHead">
                  <PeopleOutline className="icon" />
                  <span>Manage Sub admins</span>
                </div>
              </div>
              <div className="sidebarSelectList">
                <NavLink to="/admin/admin-users">
                  <div>Created Sub Admins</div>
                </NavLink>
                <NavLink to="/admin/sub-admins">
                  <div>Upgraded to Sub admins</div>
                </NavLink>
              </div>
            </div>
            <hr></hr>
          </>
        )}
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Users</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <StoreMallDirectoryOutlined className="icon" />
              <span>Business Directories</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/business-directories">
              <div>All Directories</div>
            </NavLink>
            <NavLink to="/admin/saved-draft">
              <div> Saved Draft </div>
            </NavLink>
            <NavLink to="/admin/archived-business-profiles">
              <div>Archvied</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Users</h3>
          <div className="sidebarSelect">
            <NavLink to="/admin/all-groups">
              <div className="sidebarSelectHead">
                <Diversity1Outlined className="icon" />
                <span>Users Groups</span>
              </div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Inbox</h3>
          <div className="sidebarSelect">
            <NavLink to="/admin/contact-us-messages">
              <div className="sidebarSelectHead">
                <MailOutlined className="icon" />
                <span>Users Contact Us</span>
              </div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Contacts</h3>
          <div className="sidebarSelect">
            <NavLink to="/admin/assistant-contacts">
              <div className="sidebarSelectHead">
                <ContactEmergencyOutlined className="icon" />
                <span>Our Assitants</span>
              </div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Reports</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <ReportGmailerrorredOutlined className="icon" />
              <span>Report Management</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/reported-profiles">
              <div>Reported Profiles</div>
            </NavLink>
            <NavLink to="/admin/reported-post">
              <div>Reported Posts</div>
            </NavLink>
            <NavLink to="/admin/reported-comment">
              <div>Reported Comments</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Article & Topic</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <ArticleOutlined className="icon" />
              <span>Web Management</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/reason-solution">
              <div>Articles</div>
            </NavLink>
            <NavLink to="/admin/topics">
              <div>Topics</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Posts Management</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <DirectionsCar className="icon" />
              <span>Posts Management</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/make-new-model">
              <div>Car Model</div>
            </NavLink>
            <NavLink to="/admin/users-posts">
              <div>Posts</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Payment & Subscription</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <PaidOutlined className="icon" />
              <span>Payment & Subscription</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            {user && user.role === "sub-admin" ? null : (
              <>
                <NavLink to="/admin/all-transactions">
                  <div>Users Payments</div>
                </NavLink>
                <NavLink to="/admin/plans">
                  <div>Plans</div>
                </NavLink>
              </>
            )}
            <NavLink to="/admin/subscriptons">
              <div>Purchased Subscriptions</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Content</h3>
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <ContentPasteOutlined className="icon" />
              <span>Content</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/admin/set-faq">
              <div>FAQ</div>
            </NavLink>
            <NavLink to="/admin/set-terms-&-condition">
              <div>Terms & Conditions</div>
            </NavLink>
            <NavLink to="/admin/set-privacy-policy">
              <div>Privacy Policy</div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
