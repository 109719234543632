import { AddToPhotos } from '@mui/icons-material'
import { Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import "./userDetail.css"
import Loader from '../../components/loader/Loader'


const BackPaper =  styled(Paper)({
  height:"600px",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const AssistDetail = ({objs}) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [det,setDet] = useState([]);
  const [edit,setEdit] = useState(false)
  const [image,setImage]=useState("")
  const [image2,setImage2]=useState("")
  const [obj,setObj] = useState(
    {name:"",contact:""}
  )


  const imageChange=(e)=>{
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    }
  }
  const handlecChange = () =>{
    setEdit(true)
  }

  

  const addata = (e) => {
    setObj({
        ...obj,
        [e.target.name] : e.target.value.trim(),
     })
  }
  const getAll = async ()=>{
    const result = await APIFunctions.getAssistant(location.state.id);
    if(result.data.code===200){
        setIsLoading(false)   
        setDet(result.data.data) 
        }else if(result.data.code===201){
          toast.error("error are coming for fetching images")
        }else if(result.data.code===401){
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate('/admin')
          toast.info("Session expired")
        }
    } 
    const update = async ()=>{
        let id = location.state.id
      const result = await APIFunctions.editAssistant(id,obj);
      if(result.data.code===200){
          setIsLoading(false)   
          setEdit(false)   
          toast.success("Updated successfully!")  
        }else if(result.data.code===201){
            toast.error("error are coming for fetching images")
          }else if(result.data.code===401){
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
          }
      }
    useEffect(()=>{
      setIsLoading(true)
      getAll()
    },[])

  return (
    <>
    {isLoading?<Loader />:
    <>
      <Toptag />
    <ToastContainer />
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"20px",fontWeight:"400",color:"grey"}}>Contact Details</Grid>
      <Grid item>
        {!edit?
        <button className='newBtn' onClick={handlecChange}>Update</button>
        :
        null
        }
      </Grid>
    </Grid>
    <hr></hr>
    
    <Grid container spacing={2}>
    {det.map((element,index)=>{
        return(
      <>
      <Grid xs={12} sm={12} md={6} lg={4} item key={index + 1}>
            <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
              <img src="/images/blank_pic.png" style={{ height: "150px", width: "150px", borderRadius: "5px" }} alt="" />
              </Grid>
              <Grid item>
              {edit?<button className='newBtn' onClick={update}>Save</button>:null}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={8} item>
              <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>Name</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit?true:false} placeholder={element.name} name="name" onChange={addata} className="detailBarInput" />
                </Grid>
                <Grid item>Contact</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit?true:false} placeholder={element.contact} className="detailBarInput" onChange={addata} name="contact"/>
                </Grid>
              </Grid>
            </Grid>
               </>
        )
      })}
    </Grid>
    </BackPaper>
    </>
    }
  
    </>
  )
}

export default AssistDetail