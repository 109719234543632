import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import ChangePass from './pages/profile/ChangePass';
import UserDetail from './pages/user/UserDetail';
import User from './pages/user/User';
import Faq from './pages/content/Faq';

import FaqDetails from './pages/content/FaqDetails';
import AddnewF from './pages/content/AddnewF';
import Premium from './pages/user/Premium';
import Transaction from './pages/transaction/Transaction';
import Analytic from './pages/analytics/Analytic';
import Post from './pages/posts/Post';
import Remove from './pages/user/Remove';
import Subscribe from './pages/subscribe/Subscribe';
import PostDetail from './pages/posts/PostDetail';
import Assistant from './pages/user/Assistant';
import AssistDetail from './pages/user/AssistDetail';
import Topic from './pages/article/Topic';
import Reason from './pages/article/Reason';
import ReasonDetail from './pages/article/ReasonDetail';
import AddReason from './pages/article/AddReason';
import Contact from './pages/contact/Contact';
import ContactDet from './pages/contact/ContactDet';
import ModelCr from './pages/makemodel/ModelCr';
import Terms from './pages/content/Terms';
import Privacy from './pages/content/Privacy';
import ReportCom from './pages/report/ReportCom';
import ReportPo from './pages/report/ReportPo';
import ReportPostDetail from './pages/report/ReportPostDetail';
import SubAdmin from './pages/user/SubAdmin';
import Group from './pages/group/Group';
import Directories from './pages/business/Directories';
import GroupDetail from './pages/group/GroupDetail';
import Draft from './pages/business/Draft';
import ArchivedBusiness from './pages/business/ArchivedBusiness';
import DirectoryDetails from './pages/business/DirectoryDetails';
import AdminUsers from './pages/user/AdminUsers';
import ReportedProfiles from './pages/report/ReportedProfiles';
import Plans from './pages/plans/Plans';

export default [
    { path: "/", Component: <Home /> },
    { path: "/admin/home", Component: <Home /> },
    { path: "/admin/profile", Component: <Profile /> },
    { path: "/admin/change-password", Component: <ChangePass /> },
    { path: "/admin/users", Component: <User /> },
    { path: "/admin/removed-users", Component: <Remove /> },
    { path: "/admin/premium-users", Component: <Premium /> },
    { path: "/admin/sub-admins", Component: <SubAdmin /> },
    { path: "/admin/users", Component: <User /> },
    { path: "/admin/admin-users", Component: <AdminUsers /> },
    { path: "/admin/removed-users", Component: <Remove /> },
    { path: "/admin/premium-users", Component: <Premium /> },
    { path: "/admin/contact-us-messages", Component: <Contact /> },
    { path: "/admin/all-transactions", Component: <Transaction /> },
    { path: "/admin/reported-comment", Component: <ReportCom /> },
    { path: "/admin/reported-post", Component: <ReportPo /> },
    { path: "/admin/plans", Component: <Plans /> },
    { path: "/admin/subscriptons", Component: <Subscribe /> },
    { path: "/admin/all-groups", Component: <Group /> },
    { path: "/admin/reported-profiles", Component: <ReportedProfiles /> },
    { path: "/admin/all-groups/:id", Component: <GroupDetail /> },
    { path: "/admin/business-directories", Component: <Directories /> },
    { path: "/admin/business-directories/:id", Component: <DirectoryDetails /> },
    { path: "/admin/saved-draft", Component: <Draft /> },
    { path: "/admin/archived-business-profiles", Component: <ArchivedBusiness /> },
    { path: "/admin/subscriptons", Component: <Subscribe /> },
    { path: "/admin/set-faq", Component: <Faq /> },
    { path: "/admin/make-new-model", Component: <ModelCr /> },
    { path: "/admin/users-posts", Component: <Post /> },
    { path: "/admin/reported-post", Component: <ReportPo /> },
    { path: "/admin/reported-post/reported-post-detail", Component: <ReportPostDetail /> },
    { path: "/admin/reported-comment", Component: <ReportCom /> },
    { path: "/admin/users-analytics", Component: <Analytic /> },
    { path: "/admin/add-new-faq", Component: <AddnewF /> },
    { path: "/admin/faq/details", Component: <FaqDetails /> },
    { path: "/admin/set-terms-&-condition", Component: <Terms /> },
    { path: "/admin/set-privacy-policy", Component: <Privacy /> },
    { path: "/admin/users/:id", Component: <UserDetail /> },
    { path: "/admin/assistant-contacts/details", Component: <AssistDetail /> },
    { path: "/admin/assistant-contacts", Component: <Assistant /> },
    { path: "/admin/users-posts/post-details", Component: <PostDetail /> },
    { path: "/admin/contact-us-messages", Component: <Contact /> },
    { path: "/admin/contact-us-messages/details", Component: <ContactDet /> },
    { path: "/admin/all-transactions", Component: <Transaction /> },
    { path: "/admin/topics", Component: <Topic /> },
    { path: "/admin/reason-solution", Component: <Reason /> },
    { path: "/admin/add-new-reason-solution", Component: <AddReason /> },
    { path: "/admin/reason-solution/details-reason-solution", Component: <ReasonDetail /> },
    { path: "/login*", Component: <Home /> },
]
