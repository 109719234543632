import { Cancel, DeleteOutline, RemoveRedEye } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"


const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Topic = () => {
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [key, setKey] = useState("")
  // const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [err, setErr] = useState(false)
  const [create, setCreate] = useState(false);
  const [delopen, setDelOpen] = React.useState(false);
  const [obj, setObj] = useState(
    { tagName: "" }
  )

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (obj.tagName === '') {
      setErr(true);
    } else {
      const result = await APIFunction.createTopic(obj);
      if (result.data.code === 200) {
        setCreate(false)
        getAll();
        toast.success("Contact created succesfully!")
      } else if (result.data.code === 201) {
        toast.success("User already exist!")
      } else if (result.data.code === 401) {
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate('/login')
        toast.info("Session expired")
      }
    }
  };

  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const deleteTopic = async (id) => {
    const result = await APIFunction.deleteTopic(id);
    if (result.data.code === 200) {
      setDelOpen(false);
      getAll();
      toast.success("Successfully deleted!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('x_admintoken');
      sessionStorage.clear('x_admintoken');
      navigate("/login")
      toast.info("Session expired")
    }
  }

  const handleUserOpen = () => {
    setCreate(true);
  };

  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };

  const getAll = async () => {
    const result = await APIFunction.allTopic();
    if (result.data.code === 200) {
      setAll(result.data.data)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/login")
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    // setIsLoading(true)
    getAll()
  }, [])

  return (
    <div>
      <Toptag />
      <ToastContainer />

      <NavPaper>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div></div>
          <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <button className='newBtn' onClick={handleUserOpen}>Add Topic</button>
            </Grid>
          </Grid>
        </div>
      </NavPaper>

      <BackPaper>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {all.map((element, index) => {
              let date, datearr, newDate;
              try {
                date = new Date(element.createdAt);
                datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                  datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                  date.toISOString().substring(0, 10).split("-")[0];
              } catch (err) {
                console.error(err);
                newDate = 'InvalidDate';
              }
              return (<>
                <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{element.tagName}</TableCell>
                  <TableCell>{newDate}</TableCell>
                  <TableCell align="right">
                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                      {/*<Grid item>
                        <Tooltip title="View" >
                          <NavLink to='/admin/topics/details' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                        </Tooltip>
                      </Grid>*/}
                      <Grid item>
                        <Tooltip title='Delete Topic' >
                          <DeleteOutline className="blockIcon" style={{ color: "red" }} onClick={() => { handleDeleteOpen(element._id) }} />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </>
              )

            })}
          </TableBody>
        </Table>

        <Dialog open={create} aria-labelledby="responsive-dialog-title">
          <div className="popUpCreate">
            <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
              <Cancel style={{ fontSize: "25px", color: "white", cursor: "pointer" }} onClick={() => setCreate(false)} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className='popHeading'>Add Topic</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

              <div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                  <div className="popText">Name</div>
                  <div><input type="text" className="popInput" name="tagName" id="tagName" onChange={addata} /></div>
                  {err && obj.tagName === '' ? <div className="errText">Name is required</div> : null}
                </div>
              </div>

            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
              <div>
                <button className='popBtn' onClick={handleSubmit}>Add</button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog open={delopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete this topic?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>No</Button>
            <Button onClick={() => { deleteTopic(id) }} >Yes</Button>
          </DialogActions>
        </Dialog>

      </BackPaper>
    </div>
  )
}

export default Topic









